<template>
  <div class="intro-container -scrollable text">
      <p>
        <ul>
            <li>开始处置前，请准备好身份证或驾驶证等证件。</li>
            <li>进入处置后，如有权限申请提示，请允许对摄像头/麦克风/定位的权限请求。</li>
            <li>处置界面中，左下方相机图标为前后摄像头切换功能。</li>
            <li>通话过程中，视频画面默认为并排平铺布局，可点击相应画面切换主显。</li>
            <li>摄像头切换界面(下方示意图)，会获取到多个本机摄像头，一般第1个为前置摄像头，如未获取到准确名称可以依次切换确认。</li>
            <li>处置过程中，如需要对证件拍照，请切换为后置摄像头，完成后切回前置。</li>
            <li>处置过程中，请勿接听其它电话或视频呼叫，以免打断处置流程。</li>
            <li>预计处置时间约10-30分钟。</li>
        </ul>
      </p>
      <p style="text-align: center;">
        <img src="/intro/04.png" />
      </p>
  </div>
</template>

<style lang="scss">
.intro-container {
  .iframe {
    width: 100%;
    min-height: calc(100vh - 23px);
  }
}
</style>

<script>
export default {
  name: "app-intro",
  components: {},
  data() {
    return {
     
    }
  },
  created() {
    //console.log('app-intro created')
  },
  mounted() {
    //console.log('app-intro mounted')
  },
  beforeDestroy() {
    //console.log('app-intro beforeDestroy')
  },
  methods: {
    
  },
}
</script>
